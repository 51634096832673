import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                Sat Dispenser is no longer operational.
            </p>
            <p>
                To purchase special sats OTC, join our <a href="https://discord.gg/a6bDuhpnjj">discord</a> and ask
            </p>
            <p>
                For public marketplaces check out <a href="https://magisat.io">Magisat</a> or <a href="https://magiceden.io">Magic Eden</a>.
            </p>
            <p>
                To learn more about satoshi rarity, see the <a href="https://docs.ordinals.com/overview.html#rarity" target="_blank">ordinal docs</a>.
            </p>
        </>
    )
}

export default Lore